import { userAgentType, changeParam } from './index';

export function jumpVipPgae(from) {
  let value = from ? `?from=${from}` : '';
  if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
    window.location.href = `yyjz://Vip/center${value}`;
  }
  if (/android/i.test(navigator.userAgent)) {
    window?.android?.goToActivity2('jz://com.caiyi.accounting/com.caiyi.accounting.jz.vip.VipCenterActivity' + value);
  }
}

export function jsClosePage() {
  window?.android?.jzClose();
  window?.ios?.jzClose();
}

// 跳转客户端路由
export function jsJumpRouter(url) {
  window?.ios?.jumpRoute(url);
  window?.android?.jumpRoute(url);
}

export function jsJumpGoldRecord() {
  const { isAndroid, isPhone } = userAgentType();
  let url = isAndroid ? 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.tree.GoldDetailActivity' : 'yyjz://app/goldDetai';
  return jsJumpRouter(url);
}

export function jsJumpCoupon() { // 跳转优惠券
  const { isAndroid } = userAgentType();
  let url = isAndroid ? 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.coupon.activity.CouponListActivity' : 'yyjz://app/coupon';
  return jsJumpRouter(url);
}

export function jsJumpSignin(data) { // 跳转签到页
  const { isAndroid } = userAgentType();
  let params = '';
  if (data) params = changeParam(data);
  let url = isAndroid ? `jz://com.caiyi.accounting/com.caiyi.accounting.jz.tree.AccountTreeNewActivity${params}` : `yyjz://app/signin${params}`;
  return jsJumpRouter(url);
}

export function jsBriageEvent(data) { // 埋点事件
  data = data || {};
  data = JSON.stringify(data);
  const { isAndroid, isPhone } = userAgentType();
  if (isPhone && window.ios && window.ios.jsBriageEvent) return window.ios.jsBriageEvent(data);
  // eslint-disable-next-line max-len
  if (isAndroid && window.android && window.android.jsBriageEvent) return window.android.jsBriageEvent(data);
}

export function jsToken() { // 获取token
  let token;
  const { isAndroid, isPhone } = userAgentType();
  if (isAndroid && window.android && window.android.jzToken) {
    token = window?.android?.jzToken();
  }
  if (isPhone && window.ios && window.ios.jzAccessToken) {
    token = window?.ios?.jzAccessToken();
  }
  return token;
}

export function jsVersion() { // version
  let version = '5.5.0';
  const { isAndroid, isPhone } = userAgentType();
  if (isAndroid) version = window?.android?.jzAppVersion();
  if (isPhone) version = window?.ios?.appVersion();
  return version || '5.5.0';
}

export function jsVersionNumber() { // 版本转number类型
  let versionNumber = jsVersion();
  // versionNumber = versionNumber.replaceAll('.', '');
  versionNumber = versionNumber.split('.').join('');
  versionNumber = versionNumber ? Number(versionNumber) : 550;
  return versionNumber;
}

export function getJzUserId() {
  let appInfo = {
    cuserId: '',
  };
  const { isAndroid, isPhone } = userAgentType();
  if (isAndroid) appInfo.cuserId = window.android?.jzUserId() || '';
  if (isPhone) appInfo.cuserId = window.ios?.jzUserId || '';
  return appInfo;
}

export function jsDoSync(type = 0) { // 0: 增量同步 1：全量同步 2：数据拉取
  const { isAndroid, isPhone } = userAgentType();
  isAndroid && window?.android?.doSync(type);
  isPhone && window?.ios?.doSync(type);
}

export function jsGoSetting() { // 跳转手机设置页面
  const { isPhone } = userAgentType();
  isPhone && window?.ios?.goSetting();
}
