<template>
  <div class="page">
    <div class="container">
      <div class="help-module" @click="jumpAction">
        <div class="icon">
          <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20231219145330178-help-icon.png" alt="" />
        </div>
        <span>使用手册</span>
      </div>
      <div class="card-row">
        <div class="card-item"
        v-for="(item, index) in dataList"
          :key="`item_${index}`">
          <div class="card-item-list"
            v-for="(list, idx) in item"
            :key="`list_${idx}`"
            @click="operateAction(list)">
            <div class="card-item-left">
              <div class="card-icon">
                <img :src="list.icon" alt="" />
              </div>
             <div class="card-content">
              <div class="card-title">{{ list.title }}</div>
              <div class="card-desc">{{ list.content }}</div>
             </div>
            </div>
            <div class="card-item-right">
              <div class="arrow-icon">
                <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20231218102539568-arrow-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { jsDoSync, jsJumpRouter, jsBriageEvent } from '@/utils/bridge';
import { userAgentType } from '@/utils';
import { DATA_LIST } from './tools';

export default {
  name: 'ToolsData',
  data() {
    return {
      dataList: DATA_LIST,
    };
  },
  mounted() {
    document.title = '数据处理';
    jsBriageEvent({ eventId: 'shujuchuli_imp' });
  },
  methods: {
    operateAction(data) {
      let {
        type, androidUrl, iosUrl, jumpUrl,
      } = data;
      const { isAndroid, isPhone } = userAgentType();
      type === 'sync' && jsDoSync(0);
      type === 'all_sync' && jsDoSync(1);
      if (androidUrl && iosUrl) {
        isAndroid && jsJumpRouter(androidUrl);
        isPhone && jsJumpRouter(iosUrl);
      }
      if (jumpUrl) {
        window.location.href = jumpUrl;
      }
    },
    jumpAction() {
      this.$router.push('/tools/guide');
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 750px;
  min-height: 100%;
  background: #F6F6F6;
  margin: auto;
  .container {
    padding: 20px 0;
  }
  .card-row {
    margin: 0 26px;
  }
}
.card-item {
  background: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 40px;
  &-list {
    display: flex;
    padding: 20px 20px 20px 24px;
    justify-content: space-between;
    align-items: center;
  }
  &-left {
    display: flex;
    align-items: center;
  }
  .card-title {
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
  }
  .card-desc {
    font-size: 24px;
    font-weight: 400;
    color: #666666;
    line-height: 34px;
    margin-top: 8px;
  }
  .card-icon {
    width: 72px;
    height: 72px;
    margin-right: 20px;
  }
  .arrow-icon {
    width: 32px;
    height: 32px;
    transform: rotate(90deg);
  }
}
img {
  width: 100%;
  height: 100%;
}
.help-module {
  display: flex;
  margin-top: 20px;
  margin-left: 26px;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
  align-items: center;
  .icon {
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
}
</style>
