export const GUIDE_LIST = [
  {
    title: '1.云同步',
    content: '保护您的数据安全，会自动进行，让您在各种情况下（例如更换手机）仍可正常使用，若发现数据不对可先尝试点“云同步”哦～',
  },
  {
    title: '2.全量同步',
    content: '若云同步未能解决您的数据问题，请联系客服，在客服指导下使用此功能',
  },
  {
    title: '3.数据恢复',
    content: '轻松恢复您近30天，某一天的数据状态，省去您手动改账时间',
  },
  {
    title: '4.数据导出',
    content: '将您账本中的数据导出至Excel，配合数据导入，可本地快速修改数据',
  },
  {
    title: '5.数据导入',
    content: '可将Excel中的数据导入至账本，也可把别的平台记录的账单导入到有鱼，顺利迁移',
  },
  {
    title: '6.微信导入',
    content: '微信账单快捷一键导入账本，无需繁琐手动记账',
  },
  {
    title: '7.支付宝导入',
    content: '支付宝账单快捷一键导入账本，无需繁琐手动记账',
  },
  {
    title: '8.数据迁移',
    content: '数据搬家，可以将您A账本数据迁移到B账本，也可以将您A账户数据迁移到B账户，轻松管理数据',
  },
  {
    title: '9.数据清理',
    content: '可以清空您本机、云端的数据，且无法恢复，请谨慎使用',
  },
];

const HOST = window.location.host;

const PROTOCOL = window.location.protocol;

export const DATA_LIST = [
  [
    {
      title: '云同步', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/20231218115401186-icon1.png', content: '有鱼王牌功能，时刻保护您的数据安全', type: 'sync',
    },
    {
      title: '全量同步', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/20231218115511825-icon2.png', content: '强力保存、恢复您的所有数据', androidUrl: 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.setup.DataSyncActivity', iosUrl: 'yyjz://app/syncDataSetting',
    },
    {
      title: '数据恢复', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/2023121811554293-icon3.png', content: '轻松恢复到近30天某一天数据', androidUrl: 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.vip.VipDataTxRecoverActivity', iosUrl: 'yyjz://app/dataRecoveryNew',
    },
  ],
  [
    {
      title: '数据导入', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/2023121811572315-icon4.png', content: '可从Excel、第三方导入记账数据', jumpUrl: `${PROTOCOL}//${HOST}/record/import`,
    },
    {
      title: '数据导出', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/20231218115746748-icon5.png', content: '可将记账数据导出至Excel', androidUrl: 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.setup.DataExportActivity', iosUrl: 'yyjz://app/magicExport',
    },
    {
      title: '微信导入', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/20231218115810443-icon6.png', content: '微信账单快捷一键导入账本', androidUrl: 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.ocr.ImportAccountIntroActivity?from=0', iosUrl: 'yyjz://app/image/billlead?name=微信账单导入',
    },
    {
      title: '支付宝导入', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/20231218115844673-icon7.png', content: '支付宝账单快捷一键导入账本', androidUrl: 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.ocr.ImportAccountIntroActivity?from=1', iosUrl: 'yyjz://app/image/billlead?name=支付宝账单导入',
    },
  ],
  [
    {
      title: '数据迁移', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/20231218115951944-icon8.png', content: '数据搬家、账本迁账本、账户迁账户', androidUrl: 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.setup.MergeTypeActivity', iosUrl: 'yyjz://app/dataMergeSetting',
    },
    {
      title: '数据清理', icon: '//download-cos.yofish.com/yofish-gongjushiyebu/20231218120013665-icon9.png', content: '清空本机、云端数据', androidUrl: 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.setup.DataCleanActivity', iosUrl: 'yyjz://app/clearData',
    },
  ],
];
